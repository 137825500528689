import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import $ from "jquery";
import HoverComponent from "../Components/HoverComponent";
import PersonalizedComp from "../Components/PersonalizedComp";
import IndustryServingsComp from "../Components/IndustryServingsComp";
import { ClientComp } from "../Components/ClientComp";
import { ContentAndImage } from "../Components/ContentAndImage";
import HomePortfolioComponent from "../Components/HomePortfolioComponent";
import HelmetMetaTag from "../Components/HelmetMetaTag";
import { BreadCrumbCard } from "../Components/BreadCrumbCard";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import FAQDynamicComp from "../DynamicComponent/FAQDynamicComp";
import CompaniesSlider from "../Components/CompaniesSlider";

export function NewHomePage() {
  const [VideoData, setVideoData] = useState({
    text: "",
    btnLink: "",
    btnText: "",
  });
  const myRef = useRef(null);
  var text = [
    "<div class='typewriter'><h4 class='typed-out'>stunning designs!</h4></div>",
    "<div class='typewriter'><h4 class='typed-out'>responsive websites!</h4></div>",
    "<div class='typewriter'><h4 class='typed-out'>user-friendly mobile apps!</h4></div>",
  ];
  var subText = [
    "Our designers create cutting-edge user interfaces with appealing designs for your mobile app and website.",
    "Our responsive websites provide seamless browsing experience across all platforms and devices.",
    "Our mobile apps are designed with usability and accessibility in mind, so your brand is accessible to everyone.",
  ];
  var BtnLink = ["web-development", "mobile-app-development", "uiux-design"];
  var btnText = ["Web Development", "Mobile App Development", "UI/UX Design"];
  var counter = 0;
  var inst = null;
  var width = $(window).width();
  function change() {
    setVideoData({
      ...VideoData,
      text: text[counter],
      btnLink: BtnLink[counter],
    });
    $("#heroSubText").empty().append(subText[counter]);
    counter++;
    if (counter >= text.length) {
      counter = 0;
    }
    setTimeout(change, 5900);
  }
  useEffect(() => {
    inst = setTimeout(change, 500);
  }, []);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Sprigstack",
            "alternateName": "Sprigstack",
            "url": "https://sprigstack.com/",
            "logo": "https://sprigstack.com/Sprigstacklogo.png",
            "contactPoint": [{
              "@type": "ContactPoint",
              "telephone": "+91 8780913538",
              "contactType": "customer service",
              "areaServed": ["US", "CA", "GB", "AE", "IL", "IN"],
              "availableLanguage": "en"
            }, {
              "@type": "ContactPoint",
              "telephone": "+1 3174601173",
              "contactType": "customer service",
              "areaServed": ["US", "GB", "CA", "IN", "IL", "AE"],
              "availableLanguage": "en"
            }],
            "sameAs": [
              "https://www.instagram.com/sprigstack",
              "https://www.linkedin.com/company/sprig-stack"
            ]
          }
          `}
        </script>
      </Helmet>
      <HelmetMetaTag
        titleName="Custom Software Development Company | SprigStack"
        desc="SprigStack is a leading custom software development company specializing in website and mobile app development, Web design, SEO, and DevOps."
        canonical="https://sprigstack.com/"
        ogtitle="Custom Software Development Company | SprigStack"
        ogdesc="SprigStack is a leading custom software development company specializing in website and mobile app development, Web design, SEO, and DevOps."
        ogtype="website"
        ogurl="https://sprigstack.com/"
        ogimg="https://sprigstack.com/logo192.png"
      />
      {/* HeroImage Section */}
      {width >= 991 ? (
        <>
          <div className="position-relative">
            <div className="blackBg">
              <video
                playsInline
                width="100%"
                height="600"
                autoPlay
                loop
                muted
                defaultmuted="true"
              >
                <source src="/images/HomePageVideo.mp4" type="video/mp4" />
              </video>
              <div className="SlideTextDiv col-md-5">
                <div className="col-md-12">
                  <span className=" mb-3">
                    <h1 className="pb-4">
                      Custom Software Development Company
                    </h1>
                  </span>
                  <h3>We create</h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: VideoData.text }}
                  ></div>
                  <div id="heroSubText" className="col-md-10"></div>

                  <Link
                    to={`/${VideoData.btnLink}`}
                    className="text-decoration-none"
                  >
                    <button className="aboutsusBtn mt-4">Learn More</button>
                  </Link>
                </div>

                <div className="col-12 mt-4" style={{ display: "flex" }}>
                  <a
                    href="https://www.upwork.com/ag/sprigstack/"
                    rel="nofollow"
                    target="_blank"
                  >
                    <img
                      src="/images/UpworkLogo.svg"
                      className="img-fluid pe-4 crsr-pointer"
                      alt="Upwork icon"
                      title="Upwork Sprigstack Profile"
                    />
                  </a>
                  <a
                    href="https://www.behance.net/sprigstack"
                    rel="nofollow"
                    target="_blank"
                  >
                    <img
                      src="/images/BehanceLogo.svg"
                      className="img-fluid  pe-4  crsr-pointer"
                      alt="Behance icon"
                      title="Behance SprigStack Profile"
                    />
                  </a>
                  <a
                    href="https://clutch.co/profile/sprigstack"
                    rel="nofollow"
                    target="_blank"
                  >
                    <img
                      src="/images/clutch.svg"
                      className="img-fluid  pe-4  crsr-pointer"
                      alt="Clutch icon"
                      title="Clutch SprigStack Profile"
                    />
                  </a>
                  <a
                    href="https://www.designrush.com/agency/profile/sprigstack"
                    rel="nofollow"
                    target="_blank"
                  >
                    <img
                      src="/images/sprigstack_designrush.svg"
                      className="img-fluid  pe-4  crsr-pointer mt-1"
                      alt="Designrush icon"
                      title="Designrush SprigStack Profile"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="blackBg">
            <div className="row">
              <video
                playsInline
                width="100%"
                height="auto"
                autoPlay
                loop
                muted
                defaultmuted="true"
              >
                <source src="/images/MobileView.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="row">
              <div className="col-10 m-auto py-4" style={{ color: "#fff" }}>
                <span className="heroTitle mb-3">
                  <h1 className="pb-4">Custom Software Development Company</h1>
                </span>
                <h3>We create</h3>
                <div dangerouslySetInnerHTML={{ __html: VideoData.text }}></div>
                <div id="heroSubText"></div>
                <br />
                <a
                  href={`/${VideoData.btnLink}`}
                  className="text-decoration-none"
                >
                  <button className="aboutsusBtn mt-3">Learn More</button>
                </a>
              </div>
            </div>
            <div className="row">
              <div
                className="col-8 py-4 pt-0 ps-5"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingRight: "7px",
                }}
              >
                <a
                  href="https://www.upwork.com/ag/sprigstack/"
                  rel="nofollow"
                  target="_blank"
                >
                  <img
                    src="/images/UpworkLogo.svg"
                    className="img-fluid pe-2 crsr-pointer"
                    style={{ height: "34px" }}
                    alt="Upwork icon"
                    title="Upwork Sprigstack Profile"
                  />
                </a>
                <a
                  href="https://www.behance.net/sprigstack"
                  rel="nofollow"
                  target="_blank"
                  style={{ paddingLeft: "2px" }}
                >
                  <img
                    src="/images/BehanceLogo.svg"
                    className="img-fluid crsr-pointer"
                    style={{ height: "34px" }}
                    alt="Behance icon"
                    title="Behance SprigStack Profile"
                  />
                </a>
                <a
                  href="https://clutch.co/profile/sprigstack"
                  rel="nofollow"
                  target="_blank"
                >
                  <img
                    src="/images/clutch.svg"
                    className="img-fluid ps-2 pe-4 crsr-pointer"
                    alt="Clutch icon"
                    title="Clutch SprigStack Profile"
                  />
                </a>
                <a
                  href="https://www.designrush.com/agency/profile/sprigstack"
                  rel="nofollow"
                  target="_blank"
                >
                  <img
                    src="/images/sprigstack_designrush.svg"
                    className="img-fluid  pe-4  crsr-pointer mt-1"
                    alt="Designrush icon"
                    title="Designrush SprigStack Profile"
                  />
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      {/* Clients section */}
      <CompaniesSlider />
      {/* service section */}
      <div className="px-0 container-fluid pt-lg-5 pt-2 ">
        <div className="col-12 text-center">
          <h2 className="clr0E2B3D  headerFontSizeH6">Why Choose SprigStack</h2>
          <h3 className="h1Tag">
            We provide IT solutions that help businesses grow.
          </h3>
          <img src="images/hrImg.png" alt="" className="img-fluid" />
        </div>
        <div className="row mx-lg-5 mx-4 ">
          <div className="col-lg-12 col-md-12 col-12  my-3  ServicePara pe-md-3 setcontent textalignleft">
            Sprigstack is a leading global software development company with
            expertise in various services, including website development, custom
            web app development, mobile application development, API development
            and API integration services, web and mobile design, SEO, DevOps and
            project management.
          </div>
          <div className="col-lg-12 col-md-12 col-12  my-3  ServicePara pe-md-3 setcontent textalignleft">
            We have been in the industry for more than 7 years and are proud to
            offer our clients high-quality services at reasonable costs while
            utilizing the latest technology and a dedicated development team.
          </div>
          <div className="col-lg-12 col-md-12 col-12  my-3  ServicePara pe-md-3 setcontent textalignleft">
            Our custom software development services are reliable, scalable,
            secure, and available worldwide. We have a strong presence in the
            USA and India and are continuously expanding our reach across other
            regions.
          </div>
        </div>
        <div className="col-12 text-center pt-lg-5 pt-2 ">
          <div className="clr0E2B3D  headerFontSizeH6 mx-lg-0 mx-3">
            <h2 className="fw-600">Our Key Services</h2>
          </div>
          <img src="images/hrImg.png" alt="" className="img-fluid" />
        </div>
        <div className="bgF7F7F7 my-3 py-lg-0 mt-lg-4 py-4 divHoverEffect ">
          <ContentAndImage
            ImagePosition="left"
            DescriptionHeader="Website Development"
            DescriptionBottomLineAndButton="true"
            Image="./images/HomePageWebDevIcon.svg"
            alt="Web Development"
            title="Web Development services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-4"
            Description="Online presence is essential to develop brand awareness for all business types in the high-tech era. We are a one-of-a-kind company that is the market leader in website creation and services. You may have everything from site design to management done from one spot."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/web-development"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div>
        {/* <div className="bgF7F7F7 my-3 py-lg-0 mt-lg-4 py-4 divHoverEffect ">
          <ContentAndImage
            ImagePosition="right"
            DescriptionHeader="Web App Services"
            DescriptionBottomLineAndButton="true"
            Image="./images/WebApp.png"
            alt="Web App Services"
            title="Web App Services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-4"
            Description="Online presence is essential to develop brand awareness for all business types in the high-tech era. We are a one-of-a-kind company that is the market leader in website creation and services. You may have everything from site design to management done from one spot."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/web-application-development"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div> */}
        {/* <div className="bgF7F7F7 my-3 py-lg-0 mt-lg-4 py-4 divHoverEffect ">
          <ContentAndImage
            ImagePosition="left"
            DescriptionHeader="Ecommerce Services"
            DescriptionBottomLineAndButton="true"
            Image="./images/E-commerce.png"
            alt="Ecommerce Services"
            title="Ecommerce Services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-4"
            Description="Online presence is essential to develop brand awareness for all business types in the high-tech era. We are a one-of-a-kind company that is the market leader in website creation and services. You may have everything from site design to management done from one spot."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/ecommerce-services"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div> */}
        <div className="bgF7F7F7 my-3 divHoverEffect">
          <ContentAndImage
            ImagePosition="right"
            DescriptionHeader={
              width >= 991
                ? "Mobile Application Development"
                : "Mobile App Development"
            }
            DescriptionBottomLineAndButton="true"
            Image="./images/HomePageMobileAppDevIcon.svg"
            alt="Mobile App Development"
            title="Mobile App Development services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-5"
            Description="People today choose modern technology that is easy to use with a smartphone. We always consider mobile application development services should follow the trend and be capable of both iOS and Android mobile variants."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/mobile-app-development"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div>
        <div className="bgF7F7F7 my-3 py-lg-0 py-4 divHoverEffect">
          <ContentAndImage
            ImagePosition="left"
            DescriptionHeader="API Integration"
            DescriptionBottomLineAndButton="true"
            Image="./images/HomePageApiIntegration.svg"
            alt="API Integration"
            title="API Integration services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-4"
            Description="Experience the impact of our API Development and Integration solutions, uniquely designed to elevate your business with custom APIs, ensuring smooth and secure data exchange across all your platforms."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/api-integration-services"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div>

        {/* <div className="bgF7F7F7 my-3 py-lg-0 py-4 divHoverEffect">
          <ContentAndImage
            ImagePosition="right"
            DescriptionHeader="CRM Development Services"
            DescriptionBottomLineAndButton="true"
            Image="./images/CRM.png"
            alt="CRM Development Services"
            title="CRM Development Services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-4"
            Description="Experience the impact of our API Development and Integration solutions, uniquely designed to elevate your business with custom APIs, ensuring smooth and secure data exchange across all your platforms."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/crm-development-services"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div>
        <div className="bgF7F7F7 my-3 py-lg-0 py-4 divHoverEffect">
          <ContentAndImage
            ImagePosition="left"
            DescriptionHeader="ERP Development Services"
            DescriptionBottomLineAndButton="true"
            Image="./images/ERP.png"
            alt="ERP Development Services"
            title="ERP Development Services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-4"
            Description="Experience the impact of our API Development and Integration solutions, uniquely designed to elevate your business with custom APIs, ensuring smooth and secure data exchange across all your platforms."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/erp-development-services"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div> */}

        <div className="bgF7F7F7 my-3 py-lg-0 py-4 divHoverEffect">
          <ContentAndImage
            ImagePosition="right"
            DescriptionHeader="UI/UX Design"
            DescriptionBottomLineAndButton="true"
            Image="./images/HomePageWebMobileDesignIcon.svg"
            alt="UI/UX design"
            title="UI/UX design and development services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-5"
            Description="We provide eye-catching and distinctive website design and interface. It is the most crucial component of every website and application to provide an optimal user experience.Our theme selection is always based on what the client needs and prefers."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/uiux-design"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div>
        <div className="bgF7F7F7 my-3 py-lg-0 py-4 divHoverEffect">
          <ContentAndImage
            ImagePosition="left"
            DescriptionHeader="Search Engine Optimization"
            DescriptionBottomLineAndButton="true"
            Image="./images/HomePageSEOIcon.svg"
            alt="Search Engine Optimization"
            title="Search Engine Optimization services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-4"
            Description="In this fast-paced world, only having a web presence is insufficient. It is also essential to be noticed on search engines like Google. We conduct in-depth analysis based on the niche to improve your website's ranking."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center "
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/seo"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div>
        <div className="bgF7F7F7 my-3 py-lg-0 py-4 divHoverEffect">
          <ContentAndImage
            ImagePosition="right"
            DescriptionHeader="DevOps"
            DescriptionBottomLineAndButton="true"
            Image="./images/HomePageDevOpsIcon.svg"
            alt="Devops"
            title="Devops services"
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-5"
            Description="DevOps consulting services aim to enhance workflow efficiency and reduce errors while improving user experience. Our team specializes in activities across all business segments, creating tools that simplify processes and deliver optimal results quickly."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/devops"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div>
        <div className="bgF7F7F7 mt-3 py-lg-0 py-4 divHoverEffect">
          <ContentAndImage
            ImagePosition="left"
            DescriptionHeader="Project Management"
            DescriptionBottomLineAndButton="true"
            Image="./images/HomePageQualityAssuranceIcon.svg"
            alt="Project Management"
            title="Project Management services  "
            CustomImageClass="webDpManImage"
            CardMargin="mx-lg-5 mx-4"
            CustomDescriptionBoxClass="px-lg-4"
            Description="Market demands change over time, making it essential to upgrade project competencies. Project management is necessary, and we are committed to keeping things running smoothly and according to the client's needs."
            customDescriptionItemFirst="col-lg-4 col-md-4 col-12 text-center"
            customDescriptionItemSecond="col-lg-8 col-md-8 col-12 homePageGYB"
            RedirectUrl="/project-management"
            IsShowButton="yes"
            IsRedirectToSamePage="yes"
            CustomHeaderClass="NewServicePara setmobilecontent bold-h3"
            bodyClassCareerRequirement="d-none"
            CustomBodyClass="textalignleft"
          />
        </div>
      </div>
      <PersonalizedComp
        cardTitle="Share your idea and Let's  <br/> make it happen!"
        ContainerColor="FFFPersonalize"
        flag="1"
      />
      <div className="col-12 text-center px-lg-6 px-3 pt-5 pb-4 pt-lg-5">
        <h2 className="clr0E2B3D  headerFontSizeH6 setlineheight">
          Our Implementation Approach
        </h2>
        <h3 className="clr0E2B3D mx-lg-0 mx-3 letterSpacing headerFontSizeH3 h1Tag">
          We take care of all aspects from start to end.
        </h3>
        <img src="images/hrImg.png" alt="" className="img-fluid "></img>
      </div>
      {width >= 991 ? (
        <div className="m-auto">
          <video
            width="100%"
            height="650pc"
            controls={false}
            loop
            autoPlay
            muted
            playsInline
          >
            <source src="/images/NewApproachVideo.mp4" type="video/mp4" />
          </video>
        </div>
      ) : (
        <>
          <div className="col-12 text-center">
            <img
              src="./images/ourApproachImg.svg"
              alt="logo"
              className="image-fluid"
            />
          </div>
        </>
      )}
      {/* Port Folio section */}
      <div className="container-fluid px-0 pt-0" ref={myRef}>
        <div className="bgF7F7F7 pt-5 pt-lg-5">
          <div className="col-12 py-4 text-center pt-0">
            <h2 className="clr0E2B3D headerFontSizeH6 setlineheight recenthdpd">
              Some of Our Recent Work Samples
            </h2>
            <h3 className="clr0E2B3D headerFontSizeH3 h1Tag recentsubhd">
              We proudly serve clients around the globe.
            </h3>
            <img src="images/hrImg.png" alt="" className="img-fluid " />
          </div>
          <HomePortfolioComponent
            ImagePosition="right"
            mainClass="page fs pb-5"
            PortfolioCardBGC="#E7FFED"
            title="MarketWagon - Local Food Delivered"
            titleRedirectUrl=""
            borderColor="#00671c"
            customSectionCol="col-lg-8 col-md-8 col-12"
            customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
            customSection2Col="col-lg-6 col-md-6 col-6"
            customSection2ColPadding="1.8rem"
            sectionOneTextOne="1M+"
            sectionOneTextTwo="Orders Delivered"
            sectionTwoTextOne="4.5"
            sectionTwoTextTwo="Average Rating"
            imgSrc="showcase1.webp"
            sectionTextColor="Cl00671C"
            linkTitle=""
            cardClass="PortFoliocard"
            redirecturl="https://marketwagon.com/"
            customLineHeight="34px"
            customMarginImg="p-lg-0 p-4"
          />
          <HomePortfolioComponent
            ImagePosition=""
            mainClass="page sc pb-5"
            PortfolioCardBGC="#EFFAFF"
            title="TreatMe - Doctor at Your Fingertip"
            titleRedirectUrl=""
            borderColor="#EFFAFF"
            customSectionCol="col-lg-9 col-md-9 col-12"
            customSection1Col="col-lg-5 col-md-5 col-6 ps-4"
            customSection2Col="col-lg-5 col-md-5 col-6"
            customSection2ColPadding="2rem"
            sectionOneTextOne="10K+"
            sectionOneTextTwo="Appts Booked"
            sectionTwoTextOne="4.5"
            sectionTwoTextTwo="Average Rating"
            imgSrc="showcasee 2 new.png"
            customMarginImg="p-lg-0 p-4 py-5"
            sectionTextColor="cls03658C"
            linkTitle=""
            cardClass="PortFoliocard"
            redirecturl="https://www.gocoworq.com/"
            customLineHeight="34px"
          />
          <HomePortfolioComponent
            ImagePosition="right"
            mainClass="page th pb-5"
            PortfolioCardBGC="#FFF0EB"
            title="Berger’s Table Pads - Custom Made Table Pads Online"
            titleRedirectUrl=""
            borderColor="#7C331E"
            customSectionCol="col-lg-9 col-md-9 col-12"
            customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
            customSection2Col="col-lg-6 col-md-6 col-6 ps-4"
            customSection2ColPadding="0px"
            sectionOneTextOne="100+"
            sectionOneTextTwo="Daily Online Orders"
            sectionTwoTextOne="Top 3"
            sectionTwoTextTwo="SEO Ranking"
            imgSrc="showcase3.webp"
            sectionTextColor="Cl7C331E"
            linkTitle=""
            cardClass="PortFoliocard"
            redirecturl="https://tablepads.com/"
            customLineHeight="45px"
            customMarginImg="p-lg-0 p-4"
          />
          <HomePortfolioComponent
            ImagePosition=""
            mainClass="page fr pb-5"
            PortfolioCardBGC="#E8F6FF"
            title="AddressTwo - Small Business CRM"
            titleRedirectUrl=""
            borderColor="#0090E5"
            customSectionCol="col-lg-10 col-md-10 col-12"
            customSection1Col="col-lg-6 col-md-6 col-6 ps-4"
            customSection2Col="col-lg-6 col-md-6 col-6"
            customSection2ColPadding="1.5rem"
            sectionOneTextOne="5M+"
            sectionOneTextTwo="Daily Emails Delivered"
            sectionTwoTextOne="100+"
            sectionTwoTextTwo="Monthly New Signups"
            imgSrc="showcase4.webp"
            sectionTextColor="Cl0090E5"
            button="true"
            linkTitle=""
            cardClass="PortFoliocard"
            redirecturl="https://www.addresstwo.com/"
            customLineHeight="34px"
            // customMarginImg = "MobileSetImage"
            customMarginImg="p-lg-0 p-4"
          />
        </div>
      </div>
      {/* personalized action */}
      <PersonalizedComp
        cardTitle="Ready to turn your idea into reality? <br/> Let's get started!"
        ContainerColor="cA49F9D"
        flag="2"
      />
      <ClientComp />
      {/* Engagement Models */}
      <div className="container-fluid pt-lg-5 pt-5">
        <div className="col-12 text-center">
          <h2 className="clr0E2B3D  headerFontSizeH6">Ways to Work with us</h2>
          <h3 className="clr0E2B3D letterSpacing headerFontSizeH3 h1Tag waysSubhd">
            We offer flexible engagement models while also being
            budget-friendly.
          </h3>
          <img src="images/hrImg.png" alt="" className="img-fluid "></img>
        </div>
        <div
          className="row my-4 pb-3 mx-lg-5 mx-4"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <BreadCrumbCard
            ColSize="col-md-4 ps-0 py-md-0 py-2 pt-0 Removepadding"
            Image="./images/waystowork1.svg"
            ImageContainerClass="textandItemCenter h-245"
            CustomHeaderClass="pdd-font1 place-content-center"
            Header="Fixed Cost Model"
            CustomBodyClass="text-justify"
            Body="This model provides fast and straightforward solutions for small websites and mobile apps with limited information or products, at a fixed and affordable cost."
            IsBulletsVisible={true}
            UlData="Ideal for small websites and mobile apps&UlData&Specialized fixed cost evaluation for specific requirements&UlData&Speedy problem-solving and a financially viable approach"
            IsCard="false"
            Heading4={true}
            IsSetClass={false}
            CustomClass="pt-lg-4 pt-0 workWithUsContainer"
          />
          <BreadCrumbCard
            ColSize="col-md-4 py-md-0 py-2  pt-0 Removepadding textalignleft"
            Image="./images/waystowork2.svg"
            ImageContainerClass="textandItemCenter h-245"
            CustomHeaderClass="pdd-font1 place-content-center"
            Header="Hire Dedicated Resources"
            CustomBodyClass="text-justify"
            Body="With this model, medium to large-scale projects can have dedicated, distraction-free resources, with costs depending on the technology used and the level of experience."
            IsBulletsVisible={true}
            UlData="Highly preferred for large-scale projects&UlData&Dedicated resources exclusively assigned to the client's project&UlData&No distractions from other work during the engagement period"
            IsCard="false"
            Heading4={true}
            IsSetClass={false}
            CustomClass="pt-lg-4 pt-0 workWithUsContainer"
          />
          <BreadCrumbCard
            ColSize="col-md-4 py-md-0 py-2 Removepadding textalignleft"
            Image="./images/waystowork3.svg"
            ImageContainerClass="textandItemCenter h-245"
            CustomHeaderClass="pdd-font1 mt-3 mt-md-2  place-content-center"
            Header="Hourly Charged Model"
            CustomBodyClass="text-justify"
            Body='This engagement model operates "as and when required", hourly basis, making it ideal for adding extra features or resolving bugs in existing projects.'
            IsBulletsVisible={true}
            UlData="Perfect for non-continuous projects&UlData&Operates on as-needed, Clients only pay on hourly basis&UlData&Ideal for adding extra features or resolving quick bugs after project completion"
            IsCard="false"
            Heading4={true}
            IsSetClass={false}
            CustomClass="pt-lg-4 pb-0 workWithUsContainer"
          />
        </div>
      </div>
      {/* Industry Servings */}
      <IndustryServingsComp
        bgColorClass="bgF7F7F7 mt-lg-0 mt-4"
        HeaderPadding="py-3 pt-5 pt-lg-5"
      />
      {/* FAQ's */}
      <FAQDynamicComp />
    </>
  );
}
