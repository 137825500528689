import React, { useReducer, useRef, useState } from "react";
import HoverComponent from "./Components/HoverComponent";
import { Link } from "react-router-dom";
import { formReducer, INITIAL_STATE } from "./Reducer/UserDetailsReduser";
import PhoneInput from "react-phone-input-2";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import CompaniesSlider from "./Components/CompaniesSlider";

export default function Referral() {
  const ReCAPTCHAValue = useRef();
  const [state, dispatch] = useReducer(formReducer, INITIAL_STATE);
  const [country, setCountry] = useState(sessionStorage.getItem("Country"));
  const [successMsg, setSuccessMsg] = useState({
    errMsg: "",
    mailSentMsg: false,
  });

  const handleChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };

  const ReferralSubmit = (e) => {
    e.preventDefault();
    setSuccessMsg({ ...successMsg, errMsg: "" });
    if ($("#ContactNumber").val().length < 8) {
      setSuccessMsg({
        ...successMsg,
        errMsg: "Please Enter Phone Number",
      });
    } else if (ReCAPTCHAValue.current.getValue() == "") {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please complete the captcha",
      });
    } else if ($("#ContactNumber").val().length < 8) {
      setSuccessMsg({
        ...successMsg,
        reCaptchaErrMsg: "Please Enter Phone Number",
      });
    } else {
      var formdata = new FormData();
      formdata.append("Name", state.Name);
      formdata.append("ContactNumber", $("#ContactNumber").val());
      formdata.append("Email", state.Email);
      formdata.append("ProjectDetail", state.ProjectDetail);
      formdata.append("ProjectBrief", state.ProjectBrief);
      formdata.append("GoogleCaptcha", ReCAPTCHAValue.current.getValue());
      formdata.append("Subject", "From Referral Page");
      formdata.append("PageName", "/referral-page");
      var requestOptions = {
        method: "POST",
        body: formdata,
      };

      fetch("https://app.addresstwo.com/SprigstackEmail/Index", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          var results = JSON.parse(result);
          if (results.Status == "Success" && results.Message == "Success") {
            setSuccessMsg({ ...successMsg, mailSentMsg: true });
          } else {
            console.log("Error:", results.Message);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div className="refdiv text-center position-relative">
          <h1 className="refHeading">
            Claim up to <span className="discount">10%</span>
            <br className="d-block d-md-none" /> Discount Now!
          </h1>
          <br className="d-none d-md-block" />
          <h2 className="refText">
            Your friend's referral link gets you extra perks for your project 🤩
          </h2>
          <br className="d-none d-md-block" />
          <h2 className="refText px-md-5 px-2 py-1 highlight">
            <span style={{ border: "1px solid #6BB7FC" }}>
              Let's code your vision Together!
            </span>
          </h2>

          <Link to="/">
            <img
              src="/images/ss-referral.png"
              alt="ss-ref"
              className="position-absolute ss-ref clickable bottom-0 start-50 translate-middle-x"
            />
          </Link>
        </div>

        <CompaniesSlider />

        <div className="row px-6 my-5">
          <div className="col-md-5 referralForm p-5">
            {!successMsg.mailSentMsg ? (
              <>
                <h3 className="refFormText text-center">
                  Drop your details below, and we will contact you within 24
                  hours.
                </h3>

                <form onSubmit={ReferralSubmit}>
                  <div className="col-lg-12 col-md-12 col-12 mb-4 mt-5 position-relative">
                    <label htmlFor="Name" className="customLabel">
                      Name
                    </label>
                    <input
                      type="text"
                      className="customInput"
                      name="Name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 mt-5 position-relative">
                    <label htmlFor="Email" className="customLabel">
                      Email
                    </label>
                    <input
                      type="email"
                      className="customInput"
                      name="Email"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 mb-4 mt-5 position-relative">
                    <div className="customPhoneInputWrapper">
                      <PhoneInput
                        country={country}
                        inputProps={{
                          name: "ContactNumber",
                          required: true,
                          id: "ContactNumber",
                          placeholder: "Phone Number*",
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 mb-4 mt-5 position-relative">
                    <label htmlFor="ProjectDetail" className="customLabel">
                      Area of interest
                    </label>
                    <select
                      style={{ color: "inherit", height: "35px" }}
                      className="customInput"
                      name="ProjectDetail"
                      onChange={handleChange}
                      required
                    >
                      <option value="Area of interest">
                        Request a consultation
                      </option>
                      <option value="Hire Developers">Hire Developers</option>
                      <option value="Website Development">
                        Website Development
                      </option>
                      <option value="App Development">App Development</option>
                      <option value="API Integration Services">
                        API Integration Services
                      </option>
                      <option value="UI/UX project">UI/UX project</option>
                      <option value="SEO services">SEO services</option>
                      <option value="DevOps">DevOps</option>
                      <option value="Project Management">
                        Project Management
                      </option>
                      <option value="Project Estimation">
                        Project Estimation
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12 mb-4 mt-5 position-relative">
                    <label htmlFor="ProjectBrief" className="customLabel">
                      Message
                    </label>
                    <textarea
                      className="customInput"
                      name="ProjectBrief"
                      rows="4"
                      placeholder="Let us know your project idea and we will make it reality"
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>{" "}
                  <div className="col-lg-12 col-md-12 col-12 mb-4 mt-5 justify-content-center align-items-center d-flex flex-column">
                    <ReCAPTCHA
                      ref={ReCAPTCHAValue}
                      sitekey={process.env.REACT_APP_EMAIL_RECAPTCHA_SITE_KEY}
                    />

                    <span className="text-danger my-2">
                      {successMsg.reCaptchaErrMsg}
                    </span>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-8 col-md-8 col-12 text-center m-auto">
                      <input
                        style={{ background: "#b60117", border: "0px" }}
                        className="referralBtn"
                        type="submit"
                        value="Send"
                      />
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <>
                <div className="text-center h3">
                  Thank you for reaching out to us. We will get back to you
                  shortly!
                </div>
              </>
            )}
          </div>
          <div className="col-md-1 OrText d-flex justify-content-center align-items-center my-3 my-md-0">
            OR
          </div>
          <div className="col-md-6 referralForm py-5 px-md-5 px-0">
            <h3 className="refFormText text-center">
              Get started right away by scheduling a free consultation call at
              your convenience.
            </h3>
            <div
              className="calendly-inline-widget calender p-3"
              data-url="https://calendly.com/sprigstack-consultation/30min"
            ></div>
          </div>
        </div>

        <div className="serviceDiv">
          <div className=" row px-6 h-100">
            <div className="col-md-3 col-6 position-relative">
              <Link to="/mobile-app-development">
                <img
                  src="/images/referralimages/image1.png"
                  className="img-fluid"
                  alt="app-development"
                />
                <p>
                  App
                  <br /> Development
                </p>
              </Link>
            </div>
            <div className="col-md-3 col-6 position-relative">
              <Link to="/api-integration-services">
                <img
                  src="/images/referralimages/image2.png"
                  className="img-fluid"
                  alt="api-integration"
                />
                <p>
                  Api
                  <br /> Integration
                </p>
              </Link>
            </div>
            <div className="col-md-3 col-6 position-relative mt-3 mt-md-0">
              <Link to="/web-development">
                <img
                  src="/images/referralimages/image3.png"
                  className="img-fluid"
                  alt="web development"
                />
                <p>
                  Web
                  <br /> Development
                </p>
              </Link>
            </div>
            <div className="col-md-3 col-6 position-relative mt-3 mt-md-0">
              <Link to="/uiux-design">
                <img
                  src="/images/referralimages/image4.png"
                  className="img-fluid"
                  alt="ui/ux"
                />
                <p>
                  UX/UI
                  <br /> Design
                </p>
              </Link>
            </div>
          </div>
          <div className=" row px-6 h-100 mt-3">
            <div className="col-md-3 col-6 position-relative">
              <Link to="/devops">
                <img
                  src="/images/referralimages/image5.png"
                  className="img-fluid"
                  alt="devops"
                />
                <p>Devops</p>
              </Link>
            </div>
            <div className="col-md-3 col-6 position-relative">
              <Link to="/portfolio">
                <img
                  src="/images/referralimages/image6.png"
                  className="img-fluid"
                  alt="portfolio"
                />
                <p>
                  Our
                  <br /> Portfolio
                </p>
              </Link>
            </div>
            <div className="col-md-3 col-6 position-relative mt-3 mt-md-0">
              <Link to="/seo">
                <img
                  src="/images/referralimages/image7.png"
                  className="img-fluid"
                  alt="seo "
                />
                <p>Seo</p>
              </Link>
            </div>
            <div className="col-md-3 col-6 position-relative mt-3 mt-md-0">
              <Link to="/project-management ">
                <img
                  src="/images/referralimages/image8.png"
                  className="img-fluid"
                  alt="project management"
                />
                <p>
                  Project
                  <br /> Management
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
