import React, { useEffect } from "react";

import HelmetMetaTag from "./Components/HelmetMetaTag";
import ContactUsComp from "./Components/ContactUsComp";
import { useLocation } from "react-router-dom";
import HoverComponent from "./Components/HoverComponent";
import CompaniesSlider from "./Components/CompaniesSlider";

export default function ContactUs() {
  const location = useLocation();

  return (
    <>
      <HelmetMetaTag
        titleName="Contact Us | SprigStack"
        desc="Contact Us"
        canonical="https://sprigstack.com/contact-us"
      />
      <div className="col-12 text-center pt-lg-5 pt-4">
        <h1 className="cfont1 fontColorBlue">CONTACT US</h1>
        <img src="./images/hrImg.png" alt="" className="img-fluid" />
        <h2
          className="headerFontSizeH3New fontColorBlue mx-lg-5 px-lg-2 my-lg-4 mx-4 my-4 setdivcontent"
          style={{ fontSize: "18px", lineHeight: "25px" }}
        >
          Need help improving your online presence? Our team of experts can
          provide tailored solutions to help grow your business.
          <br />
          Simply fill out the form below and our team will respond within 24
          hours.
        </h2>
      </div>

      {/* <ContactUsComp pageName="Contact-Us" /> */}
      <ContactUsComp
        pageName="Contact-Us"
        prevPage={location?.state?.pathname}
      />

      <div className="container-fluid p-0">
        <div className="colorWhite mt-2 py-2">
          <CompaniesSlider />
        </div>
      </div>

      <div className="col-12 text-center pt-5">
        <span className="cfont1 fontColorBlue">Office Locations</span>
        <br />
        <img src="./images/hrImg.png" alt="" className="img-fluid" />
      </div>
      <div className="col-12 pt-4">
        <div className="row ">
          <div className="col-lg-12">
            <div style={{ overflow: "hidden" }}>
              <iframe
                src="https://www.google.com/maps/d/u/0/embed?mid=1CbMVeXugmvrlQrC2z5aGP9oezuOCzKQ&ehbc=2E312F"
                width="100%"
                height="480"
                loading="lazy"
                className="googleMapsTest"
                frameBorder="0"
                style={{
                  marginTop: "-70px",
                }}
              ></iframe>
            </div>
          </div>
        </div>
        <div className="row px-5  ">
          <div className="col-md-2"></div>
          <div className="col-md-8 d-md-flex mb-4">
            <div className="col-md-6 text-center">
              <div className="headerFontSizeH3NewBold pt-4 fontColorBlue">
                <img
                  src="./images/IndiaFlag.svg"
                  alt=""
                  className="img-fluid pe-4"
                />{" "}
                INDIA (HQ)
              </div>
              <div
                className="pt-4 contactAdd"
                style={{ fontSize: "18px", fontWeight: "600" }}
              >
                D-402, THE FIRST, behind Keshavbaug Party Plot,
                <br /> I I M, Vastrapur, Ahmedabad,
                <br /> Gujarat 380015
                <br />
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="headerFontSizeH3NewBold pt-4 fontColorBlue ">
                <img
                  src="./images/UsaFlag.svg"
                  alt=""
                  className="img-fluid pe-4"
                />{" "}
                USA
              </div>
              <div
                className="pt-4 contactAdd"
                style={{ fontSize: "18px", fontWeight: "600" }}
              >
                6802 Hillsdale Ct,
                <br />
                Indianapolis, IN 46250
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </>
  );
}
