import React, { Fragment } from "react";
import HoverComponent from "./HoverComponent";

export default function CompaniesSlider({ page }) {
  return (
    <Fragment>
      <div className="container-fluid p-0">
        <div className={`${page} colorWhite d-flex align-items-center`}>
          <h2 className="pr-3 clientdiv ">Our Clients</h2>
          <logo-slider style={{ marginTop: "0px" }}>
            <div>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="Berger's Table Pads & Table Cloth logo"
                  title="Our Project Berger's Table Pads & Table Cloth logo"
                  imgSrc="client1Hover"
                  hoverSrc="client1Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="gocoworq logo"
                  title="Our Project gocoworq"
                  imgSrc="client2Hover"
                  hoverSrc="client2Hover"
                />
              </a>

              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="talknsave logo"
                  title="Our Project  talknsave"
                  imgSrc="client3Hover"
                  hoverSrc="client3Hover"
                />
              </a>
              <HoverComponent
                alt="secureroofing logo"
                title="Our Project secureroofing"
                imgSrc="client4Hover"
                hoverSrc="client4Hover"
              />

              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="addresstwo logo"
                  title="Our Project addresstwo"
                  imgSrc="client5Hover"
                  hoverSrc="client5Hover"
                />
              </a>

              <HoverComponent
                alt="alwajeeh logo"
                title="Our Project alwajeeh"
                imgSrc="client6Hover"
                hoverSrc="client6Hover"
              />

              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="Marketwagon logo"
                  title="Our Project  Marketwagon"
                  imgSrc="client7Hover"
                  hoverSrc="client7Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="Cricbuddy logo"
                  title="Our Project  Cricbuddy"
                  imgSrc="client16Hover"
                  hoverSrc="client16Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="Johnson logo"
                  title="Our Project  Johnson"
                  imgSrc="client17Hover"
                  hoverSrc="client17Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="PathSolution logo"
                  title="Our Project  PathSolution"
                  imgSrc="client18Hover"
                  hoverSrc="client18Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="THW logo"
                  title="Our Project  THW"
                  imgSrc="client19Hover"
                  hoverSrc="client19Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="K12-Coop logo"
                  title="Our Project  K12-Coop"
                  imgSrc="client20Hover"
                  hoverSrc="client20Hover"
                />
              </a>
            </div>
            <div>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="Berger's Table Pads & Table Cloth logo"
                  title="Our Project Berger's Table Pads & Table Cloth logo"
                  imgSrc="client1Hover"
                  hoverSrc="client1Hover"
                />
              </a>

              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="gocoworq logo"
                  title="Our Project gocoworq"
                  imgSrc="client2Hover"
                  hoverSrc="client2Hover"
                />
              </a>

              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="talknsave logo"
                  title="Our Project  talknsave"
                  imgSrc="client3Hover"
                  hoverSrc="client3Hover"
                />
              </a>
              <HoverComponent
                alt="secureroofing logo"
                title="Our Project secureroofing"
                imgSrc="client4Hover"
                hoverSrc="client4Hover"
              />

              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="addresstwo logo"
                  title="Our Project addresstwo"
                  imgSrc="client5Hover"
                  hoverSrc="client5Hover"
                />
              </a>

              <HoverComponent
                alt="alwajeeh logo"
                title="Our Project alwajeeh"
                imgSrc="client6Hover"
                hoverSrc="client6Hover"
              />

              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="Marketwagon logo"
                  title="Our Project  Marketwagon"
                  imgSrc="client7Hover"
                  hoverSrc="client7Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="Cricbuddy logo"
                  title="Our Project  Cricbuddy"
                  imgSrc="client16Hover"
                  hoverSrc="client16Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="Johnson logo"
                  title="Our Project  Johnson"
                  imgSrc="client17Hover"
                  hoverSrc="client17Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="PathSolution logo"
                  title="Our Project  PathSolution"
                  imgSrc="client18Hover"
                  hoverSrc="client18Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="THW logo"
                  title="Our Project  THW"
                  imgSrc="client19Hover"
                  hoverSrc="client19Hover"
                />
              </a>
              <a className="d-flex align-items-center">
                <HoverComponent
                  alt="K12-Coop logo"
                  title="Our Project  K12-Coop"
                  imgSrc="client20Hover"
                  hoverSrc="client20Hover"
                />
              </a>
            </div>
          </logo-slider>
        </div>
      </div>
    </Fragment>
  );
}
